import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SideBar from '../components/SideBar';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import moment from 'moment/moment';
import Empty from '../components/Empty';
import { get } from '../utils/request';
import { number_format } from '../utils/functions';
import Header from './Header';

export default function Transactions() {
    const { t } = useTranslation();
    const [models, setModels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let response = await get("account/transactions");

            setModels(response);
            setIsLoading(false);
        }

        fetchData();
    }, []);

    return (
        <div className={`rizanova-account`}>
            <Header />

            <Helmet>
                <title>{"Транзакции"}</title>
            </Helmet>

            <div className="container min-view">
                <h1 className="big-header font-weight-400">{t("Профиль")}</h1>

                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <SideBar />
                    </div>

                    <div className="col-lg-9 col-md-8 ">
                        <h1 className="title-1 mt-0">{t("Транзакции")}</h1>

                        {isLoading ? (<i className="fa fa-spinner rotating fa-2x text-dark"></i>) : (
                            <>
                                {models.length === 0 && (<Empty text={t("Транзакции не найдены")} />)}

                                {models.length > 0 && <div className='overlow-x-auto w-100'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t("Сумма")}</th>
                                                <th>{t("Статус")}</th>
                                                <th>{t("Дата")}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {models.map((model, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {model.status == 1 && (<NavLink to={`/transaction/${model.unique_id}`} className="btn btn-spec btn-spec-smaller text-white">Оплатить</NavLink>)}
                                                        </td>
                                                        <td>
                                                            {number_format(model.currency, 0, ".", " ")} сум
                                                        </td>
                                                        <td>
                                                            {model.status == 1 ? (
                                                                <span className="text-warning">{t("Не оплачен")}</span>
                                                            ) : model.status == 2 ? (
                                                                <span className="text-success">{t("Оплачен")}</span>
                                                            ) : (
                                                                <span className="text-danger">{t("Отменен")}</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {moment(model.created_at, "YYYY-MM-DD H:m:s").format("DD.MM.YYYY / HH:mm")}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}