export const initialAuthState = {
    isAuth: false,
    user: null,
};

export const authReducer = (prevState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                isAuth: action.payload !== null,
                user: action.payload,
            };

        case 'LOGOUT':
            return initialAuthState;

        default:
            return prevState;
    }
};
