import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import { AuthContext } from '../components/context';
import InputMask from '../components/InputMask';
import { useIsMounted } from '../utils/functions';
import { post } from '../utils/request';

export default function Login() {
    const { t } = useTranslation();

    const authContext = useContext(AuthContext);
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const { register, handleSubmit, setError, control, formState: { errors } } = useForm();

    const submitHandler = async (data) => {
        setIsLoading(true);

        try {
            let res = await post('user/login', {
                username: data.username,
                password: data.password,
            });

            if (res?.status === 'error') {
                if (isMounted) {
                    setError("username", { type: 'required', message: res.message })
                }
            } else {
                if (isMounted) {
                    authContext.login({ ...res.user });
                }
            }

        } catch (ex) {

        }

        setIsLoading(false);
    };

    return <div className="container py-5">
        <Helmet>
            <title>{"Авторизация"}</title>
        </Helmet>

        <div className="d-flex justify-content-center">
            <div className="rizanova-login transition-all transform">
                <div className="login">
                    <div className="d-flex justify-content-center w-100">
                        <h3>{("Войдите или зарегистрируйтесь")}</h3>
                    </div>
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <div className="form-group">
                            <div>
                                <InputMask
                                    mask="\+\9\9\8999999999"
                                    className={errors.username ? 'has-error' : ''}
                                    placeholder={t("Введите номер телефона")}
                                    control={control}
                                    name="username"
                                    rules={{
                                        required: true,
                                        pattern: /^\+[0-9]{12}$/
                                    }}
                                />
                                {errors.username && errors.username.message.length > 0 && <p className="error-block">{errors.username.message}</p>}
                            </div>
                        </div>

                        <div className="form-group mb-1 position-relative">
                            <input type={showPassword ? 'text' : "password"} {...register("password", { required: true })} className={errors.password ? 'has-error' : ''} placeholder={("Введите пароль")} />

                            <a role="button" className={"eye " + (showPassword ? 'active' : '')} onClick={() => setShowPassword(!showPassword)}>
                                {/* Eye svg */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                                    <path d="M10.0007 0.75C5.83398 0.75 2.27565 3.34167 0.833984 7C2.27565 10.6583 5.83398 13.25 10.0007 13.25C14.1673 13.25 17.7256 10.6583 19.1673 7C17.7256 3.34167 14.1673 0.75 10.0007 0.75ZM10.0007 11.1667C7.70065 11.1667 5.83398 9.3 5.83398 7C5.83398 4.7 7.70065 2.83333 10.0007 2.83333C12.3006 2.83333 14.1673 4.7 14.1673 7C14.1673 9.3 12.3006 11.1667 10.0007 11.1667ZM10.0007 4.5C8.61732 4.5 7.50065 5.61667 7.50065 7C7.50065 8.38333 8.61732 9.5 10.0007 9.5C11.384 9.5 12.5007 8.38333 12.5007 7C12.5007 5.61667 11.384 4.5 10.0007 4.5Z" />
                                </svg>
                            </a>
                        </div>

                        <div>
                            <button type="submit" className="submit" disabled={isLoading}>{
                                isLoading ? <i className="fa fa-spinner rotating"></i> : ("Войти")
                            }</button>
                        </div>

                        <div>
                            <NavLink to="/signup" className={"another-link"}>
                                {("Зарегистрироваться")}
                            </NavLink>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}