import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthContext } from './components/context';
import NotFound from './pages/NotFound';
import 'react-toastify/dist/ReactToastify.css';

import Transaction from './pages/Transaction';
import Offerta from './pages/Offerta';
import Home from './pages/Home';
import { getData, removeData, storeData } from './utils/store';
import axios from 'axios';
import { authReducer, initialAuthState } from './reducers/authReducer';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Transactions from './pages/Transactions';

function App() {
    const [isLoading, setIsLoading] = React.useState(true);

    const [authState, loginDispatch] = React.useReducer(
        authReducer,
        initialAuthState,
    );

    const authContext = React.useMemo(
        () => ({
            login: async user => {
                if (user) {
                    await storeData('user', user);
                    axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
                }

                loginDispatch({ type: 'LOGIN', payload: user });
            },
            logout: async () => {
                await removeData('user');
                loginDispatch({ type: 'LOGOUT' });
            },
            authState,
        }),
        [authState],
    );

    React.useEffect(() => {
        const fetchData = async () => {
            let user = await getData('user');

            axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    if (error.response)
                        if (error.response.status === 401) {
                            removeData('user');
                            loginDispatch({ type: 'LOGOUT' });
                        } else {
                            return Promise.reject(error);
                        }
                }
            );

            if (user)
                axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;

            authContext.login(user);

            setIsLoading(false);
        }

        fetchData();
    }, []);

    if (isLoading) {
        return <div style={{ width: "100vw", height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fa fa-spinner rotating fa-5x text-dark"></i>
        </div>
    }

    return (
        <AuthContext.Provider value={authContext}>
            <div className={`rizanova-content theme-white`}>
                <BrowserRouter>

                    {authState.user ? (
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/transactions" element={<Transactions />} />
                            <Route path="/transaction/:id" element={<Transaction />} />
                            <Route path="/offerta" element={<Offerta />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    ) : (
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/offerta" element={<Offerta />} />
                            <Route path="*" element={<Login />} />
                        </Routes>
                    )}
                </BrowserRouter>
                <ToastContainer />
            </div>
        </AuthContext.Provider>
    );
}

export default App;
