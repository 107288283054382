import React from 'react';
import { Helmet } from 'react-helmet';

export default function Offerta() {
    return (
        <div className={`rizanova-account`}>
            <Helmet>
                <title>{"Публичная офферта"}</title>
            </Helmet>

            <div className="container min-view" dangerouslySetInnerHTML={{
                __html: `
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:right;'><strong><span style='font-size:15px;font-family:"Arial",sans-serif;'>УТВЕРЖДЕН<br>&nbsp;Решением учредителя&nbsp;<br>&nbsp;ООО &laquo;</span></strong><strong><span style='font-size:15px;font-family:"Arial",sans-serif;'>RIZANOVA</span></strong><strong><span style='font-size:15px;font-family:"Arial",sans-serif;'>&raquo;<br>&nbsp;№2 от 09.09.2022 г.<br>&nbsp;<br>&nbsp;</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:15px;font-family:"Arial",sans-serif;'>ПУБЛИЧНАЯ ОФЕРТА НА ОКАЗАНИЕ УСЛУГ В СФЕРЕ ИЗГОТОВЛЕНИЕ КОНТЕНТА<br>&nbsp;<br>&nbsp;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;'><br>&nbsp;</span><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>1. Термины</span></strong><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>&nbsp;и определения, используемые в Оферте</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:15px;font-family:"Arial",sans-serif;'>&nbsp;</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><em><span style='font-size:14px;font-family:"Arial",sans-serif;'>Для целей настоящей Оферты следующие термины (в случае, если в тексте Оферты они написаны с заглавной буквы) будут иметь указанные ниже значения:&nbsp;</span></em></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><em><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></em></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;<br> <strong>&laquo;Заказчик&raquo;</strong> &ndash; любое физическое лицо, выразившее волю и намерение воспользоваться услугами, предлагаемой Исполнителем, принявшее (акцептовавшее) условия настоящей Оферты и оплатившее услуги.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br> <strong>&laquo;Исполнитель&raquo;&nbsp;</strong>&ndash; общество с ограниченной ответственностью &laquo;</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>RIZANOVA</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>&raquo;, зарегистрированное по адресу 100040, улица Шахрисабз 16 а, Мирабадского района, город Ташкент, оказывающее услуги в порядке и на условиях, предусмотренных настоящей Офертой.<strong>&nbsp;</strong></span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;<br>&nbsp;&laquo;Заказ&raquo;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;&ndash; действия Заказчика, направляющего в электронный адрес или в мессенджеры Исполнителя электронных копий Произведений, фонограмм, видеограмм и зафиксированных в них исполнений, и/или их частей для рассмотрения предоставлений Услуг.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br> <strong>&laquo;Услуги&raquo;</strong> &ndash; выполняемые работы Исполнителем по заказу Заказчика, указанные в приложении №1 к настоящей Оферте.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&laquo;Акцепт&raquo;&nbsp;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&ndash; совершение Заказчиком, получившим оферту, в срок, установленный для ее акцепта, действий по выполнению указанных в ней условий договора согласно со ст. 370 Гражданского кодекса Республики Узбекистан.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'><br>&nbsp;&laquo;Произведения&raquo;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;&ndash; аудио- и/или аудиовизуальные произведения, которые были предоставлены Заказчиком Исполнителю для получения Услуги на момент акцепта, права на которые передаются, в виде музыкальных произведений, песен, исполнений, фонограмм, видеограмм и других объектов авторского права и смежных прав.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&laquo;Контент&raquo;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;&ndash; результаты выполняемые работы (тексты, графическая, звуковая информация, трейлеры, тизеры, обложки и др. материалы.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&laquo;Дополнительный контент&raquo;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;&ndash; фото, тексты, пресс-релизы Артиста, метаданные и все другие материалы Заказчика, которые могут быть использованы Исполнителем исключительно для предоставления Услуги в рамках настоящей Оферты.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br> <strong>&laquo;Трейлер&raquo;&nbsp;</strong>&ndash; результат выполненной работы Исполнителя, небольшой рекламный ролик фильма, выставленный для промоушна в интернет.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br> <strong>&laquo;Тизер&raquo;&nbsp;</strong>&ndash; результат выполненной работы Исполнителя, рекламное фото и/или видео-сообщение, построенное как загадка, которое содержит часть Произведении и информации о ней. Тизеры обычно появляются на раннем этапе продвижение произведений и служат для создания интриги вокруг нее.&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br>&nbsp;</span><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&laquo;Переработка&raquo;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;&ndash; действия по адаптации, кодированию, маркировке, конвертации, технической переработке в любые другие форматы Произведений и/или их частей в соответствии с требованиями D</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>SP</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br> <strong>&laquo;Воспроизведение&raquo;</strong> &ndash; действия по изготовлению одного экземпляра, или более экземпляров Произведений, фонограмм, видеограмм и зафиксированных в них исполнений и/или их частей, Трейлеров, Тизеров, в цифровой или иной форме, которые могут считывать электронные устройства, с целью их использования по настоящей Оферте.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br> <strong>&laquo;Распространение&raquo;</strong> &ndash; действия по распространению Произведений, фонограмм, видеограмм и зафиксированных в них исполнений, и/или их частей и производных произведений, созданных в результате оказанной Услуги и/или Переработки, в цифровом формате среди потребителей в том числе на D</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>SP</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&laquo;</span></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>DSP</span></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&raquo;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>&ndash; <span style="color:black;">(Digital Service Provider) &ndash; технический сервис (электронная площадка/ платформа для загрузки/прослушивания/ использования музыки или видео (видеоклипов) вроде iTunes, Apple Music, Spotify, Google Play Music, YouTube, YouTube Music и т. п.), который имеет адрес или определитель местонахождения в цифровых сетях (включая on-line и off-line режимы), доступный потребителям с помощью любых технических средств.</span></span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br> <strong>&laquo;Платежные системы&raquo;</strong> &ndash; <span style="color:black;background:white;">виртуальные электронные сервисы позволяющие совершать различные платежи с помощью банковской карты или аккаунта в электронной системе такие как&nbsp;</span></span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;background:white;'>Payme</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;background:white;'>,&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;background:white;'>Click</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;background:white;'>,&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;background:white;'>Apelsin</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;background:white;'>,&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;background:white;'>Paynet</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;background:white;'>&nbsp;и т. д.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;background:white;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&laquo;Художественный совет</span></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&raquo;&nbsp;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>&ndash;<strong>&nbsp;</strong>экспертно-консультативный орган Исполнителя, занимающийся оценкой Произведений.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;background:white;'><br> <strong>&laquo;Идентификатор заказчика&raquo;&nbsp;</strong></span><span style='font-size:14px;font-family:"Arial",sans-serif;'>&ndash; уникальный идентификатор Заказчика, используемый для осуществления оплаты, предоставляемый Исполнителем после принятии Заказа.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>2. Предмет Оферты</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br>&nbsp;2.1. По настоящей оферте Исполнитель обязуется по Заказу Заказчика оказать Услуги (выполнить работы) (далее &ndash; &laquo;Услуги&raquo;), а Заказчик обязуется принять и оплатить эти Услуги.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.2. Для осуществления оказание Услуги Заказчик обязуется передавать Исполнителю неисключительные имущественные авторские и смежные права на использование Произведений, фонограмм, видеограмм и зафиксированных на них исполнений, в цифровых форматах, с использованием Дополнительного контента, а именно:</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.2.1. Право Исполнителя на Переработку, Воспроизведение и Распространение Произведений, фонограмм, видеограмм и зафиксированных в них исполнений, и / или их частей в цифровых форматах среди потребителей.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.2.2. Право Исполнителя доводить Произведения, фонограммы и зафиксированные на них исполнения, и/или их части, Трейлеров, Тизеров к всеобщему сведению таким образом, чтобы любое лицо смогло получить доступ к Произведениям, фонограммам, видеограммам и зафиксированным в них исполнениям на&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>DSP</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;с любого места и в любое время по собственному выбору в цифровых сетях (включая Интернет и другие сети) с помощью любых средств и устройств.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.3. <em>Заказчик соглашается с тем, что:</em></span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.3.1. после подачи заказа все содержание отправляется в Художественный совет Исполнителя для оценки Произведений;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.3.2. в течение 3 (трех) рабочих дней Исполнитель предоставляет Заказчику результаты оценки Произведений Художественного совета, доступ к настоящей Оферте для ознакомления и уникального Идентификатора заказчика используемый Заказчиком для осуществления оплаты;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>2.3.3. Исполнитель вправе не предоставлять подробный отчет о действиях Художественного совета по оценке Произведений.<br>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-bottom:10.0pt;text-align:center;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>3. Гарантии, права и обязанности Сторон</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.1. Заказчик гарантирует:</span></em></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.1.1. на момент акцепта настоящей Оферты он является законным обладателем прав, передаваемых Исполнителю;<br>&nbsp;3.1.2. на момент акцепта настоящей Оферты он не связан другим договором или соглашением, которые могут тем или иным способом повлиять на права Исполнителя, определенные настоящей Офертой;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.1.3. в случае если после вступления в силу Оферты, у Заказчика или у Исполнителя возникнут спорные вопросы или иски с любыми лицами по поводу переданных Исполнителю авторских и/или смежных прав относительно Произведений, фонограмм и зафиксированных на них исполнений, а также видеоклипов и видеограмм, Дополнительного контента, их урегулирование будет осуществляться непосредственно Заказчиком за свой счет, если иное не будет согласовано Сторонами, без создания препятствий для Исполнителя относительно возможности использования переданных ему прав по настоящей Оферте. Заказчик обязуется возместить расходы и убытки, понесенные Исполнителем в результате урегулирования претензий или исков, возникших в результате нарушения Заказчиком гарантий настоящей Оферты;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.1.4. использование Произведений, фонограмм и зафиксированных на них исполнений, а также видеоклипов и видеограмм, Дополнительного контента на условиях настоящей Оферты не нарушают авторских и/или смежных прав третьих лиц, прав на торговую марку или любых иных прав третьих лиц, не наносят вреда чести, деловой репутации и достоинству третьих лиц, не нарушают национальное или международное законодательство, не являются непристойными, неприемлемыми, проявляющими неуважение к суду, пропагандирующими ненависть, насилие, незаконную деятельность или дискриминацию по расовому, религиозному, половому признаку, национальному происхождению, возрасту, физической неполноценности.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.1.5. Заказчик гарантирует, что при создании Произведений, фонограмм, видеограмм, Дополнительного контента были получены все права на использование включенных в них произведений, фонограмм, изображений лиц и объектов частной или интеллектуальной собственности</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>3.2. Права Заказчика:</span></em></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>3.2.1. требовать от Исполнителя оказания Услуг соответствующего качества согласно с условиями настоящей оферты;<br>&nbsp;3.2.2. в &nbsp; случае &nbsp; оказания &nbsp; &nbsp;Услуги &nbsp; ненадлежащего &nbsp; качества по своему выбору потребовать:</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>3.2.2.1.- замены на аналогичную Услугу надлежащего качества;&nbsp;</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>3.2.2.2 - безвозмездного устранения недостатков</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>3.3. Обязанности Заказчика:</span></em></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>3.3.1 оплатить Услуги по тарифам, указанных в приложение №1 настоящей оферты;&nbsp;</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>3.3.2.&nbsp;</span></em><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>принять оказанные по его заказу Услуги в соответствии с настоящей офертой.</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>3.3.3.&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Заказчик обязуется после акцепта настоящей Оферты по требованию Исполнителя предоставлять заверенные надлежащим образом копии соглашений (контрактов, договоров), других документов, подтверждающих принадлежность ему имущественных авторских и смежных прав на использование Произведений, фонограмм, видеограмм и зафиксированных на них исполнений.</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>3.4. Права Исполнителя</span></em><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>:</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;border:none windowtext 1.0pt;padding:0cm;'>3.4.1. требовать от Заказчика осуществления предварительной оплаты и окончательных</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;<span style="border:none windowtext 1.0pt;padding:0cm;">расчетов за оказываемые &nbsp; Услуги в порядке и объемах, установленных настоящей Офертой и</span> <span style="border:none windowtext 1.0pt;padding:0cm;">действующим законодательством;<br>&nbsp;</span>3.4.2. требовать &nbsp; от &nbsp; Заказчика &nbsp; &nbsp;возмещения &nbsp; нанесенного ущерба в результате необоснованного &nbsp; отказа &nbsp; от принятия оказанных Услуг в соответствии с поданной заявкой.</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'><br> <em>3.5. Обязанности Исполнителя:</em></span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.5.1. оказывать Заказчику Услуги в сроки, количестве и качестве в соответствии с настоящей Оферты или по заказу Заказчика, подаваемой им в порядке, предусмотренном пунктом 2.3 настоящей Оферты;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.5.2. по требованию Заказчика в 3-дневный срок безвозмездно исправить все выявленные недостатки, если в процессе оказания Услуг Исполнитель допустил отступления от условий Оферты и нормативов.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>3.5.3. использовать переданные по оферте права без причинения вреда личным неимущественным правам авторов, Артистов, производителей фонограмм и видеограмм, и в случае имеющейся возможности при использовании указывать информацию о них;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>4. Цена оферты и порядок расчетов</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>4.1. Цены оказываемых Услуг указаны в приложении к настоящей оферте.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>4.2.&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>Заказчик&nbsp;осуществляет оплату в полном размере (100 процентов) за оказываемой Услуги.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-bottom:10.0pt;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>4.3. Расчеты за оказанные Услуги производятся в безналичном порядке путем оплаты через Платежные системы с указанием Идентификатора заказчика.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-bottom:10.0pt;text-align:center;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>5. Исполнение оферты</span></strong></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>5.1. Оферта должна исполняться надлежащим образом в соответствии с условиями и требованиями настоящей оферты и законодательства. Оферта считается исполненным в том случае, если стороны обеспечили исполнение всех принятых на себя обязательств.</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>5.2. Не допускается односторонний отказ от исполнения оферты или одностороннее изменение условий оферты, за исключением случаев, установленных законодательством.</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>5.3.&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;border:none windowtext 1.0pt;padding:0cm;'>Датой исполнения обязательств по оферте считается дата по истечении 3-х рабочих дней после полной оплаты&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Заказчиком<span style="border:none windowtext 1.0pt;padding:0cm;">.<br>&nbsp;5.4.&nbsp;</span>Заказчик<span style="border:none windowtext 1.0pt;padding:0cm;">&nbsp;вправе отказаться от приемки, оказанной с нарушением сроков</span> <span style="border:none windowtext 1.0pt;padding:0cm;">Услуги.</span></span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;border:none windowtext 1.0pt;padding:0cm;'>5.5.&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;border:none windowtext 1.0pt;padding:0cm;'>Оказанные &nbsp; Услуги &nbsp; &nbsp;принимаются &nbsp; &nbsp;непосредственно&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Заказчиком<span style="border:none windowtext 1.0pt;padding:0cm;">, по тем же способом, по которым была направлена Заявка.</span></span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>5.6. При наличии у Заказчика замечаний, претензий по Услуги (выполненной работы), он обязан в течение 3 (трех) рабочих дней после получения Услуги, предъявить Исполнителю мотивированные замечания, претензии в письменной форме на электронный адрес Исполнителя:&nbsp;</span><span style="color:black;"><a href="mailto:ceo@rizanova.uz"><span style='font-size:14px;font-family:"Arial",sans-serif;'>ceo</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>@</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>rizanova</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>.</span><span style='font-size:14px;font-family:"Arial",sans-serif;'>uz</span></a></span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>. В случае отсутствия замечаний, претензий к выполненной работе со стороны Заказчика в указанный срок, Услуга считается предоставленной надлежащим образом.</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;border:none windowtext 1.0pt;padding:0cm;'>&nbsp;</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:center;background:white;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;border:none windowtext 1.0pt;padding:0cm;'>6. Ответственности сторон</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'><br>&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>6.1. Стороны несут ответственность за невыполнение своих обязательств по настоящей Оферте в порядке, установленном действующим&nbsp;законодательством&nbsp;Республики Узбекистан.</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>6.2. Меры ответственности&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>сторон, не предусмотренные в настоящей оферте, применяются в соответствии с нормами гражданского законодательства.</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:justify;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:center;background:white;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;'>7. Разрешение споров</span></strong></p>
                <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;text-align:center;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>7.1. В случае возникновения споров по вопросам, являющимся предметом настоящей Оферты или в связи с Офертой, Стороны примут меры к их разрешению путем переговоров.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>7.2. В случае невозможности разрешения споров путем переговоров, все споры, которые могут возникнуть из настоящей Оферты, подлежат рассмотрению судом в соответствии с действующим законодательством Узбекистана.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>8. Конфиденциальность<br>&nbsp;</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.1. Стороны по своему усмотрению предоставляют другой Стороне доступ к конфиденциальной информации о своей деятельности и взаимно обязуются добросовестно использовать и не разглашать указанную информацию, которая стала им известна в связи с исполнением настоящей Оферты, а также информацию о коммерческих условиях в пределах, не противоречащих законодательству.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.2. Стороны несут ответственности за нарушение условий конфиденциальности настоящей Оферты в соответствии с законодательством Узбекистана.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.3. Обязательства по конфиденциальности остаются в силе в течение 3 (трех) лет после окончания действия Оферты.<br>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>9. Форс-Мажор<br>&nbsp;</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>9.1. Стороны освобождаются от ответственности, в случае обстоятельств непреодолимой силы, возникших независимо от их воли и / или вины, которые Стороны не могли предвидеть и предотвратить в обычном порядке.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>9.2. Обстоятельства, вызванные событиями, которые не зависели от воли Сторон и / или вины Стороны и действие которых добросовестная Сторона не могла избежать или устранить их последствия, считаются обстоятельствами непреодолимой силы, которые освобождают от ответственности, если они произошли после акцепта настоящей Оферты и препятствуют его полному или частичному исполнению.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>9.3. Обстоятельствами непреодолимой силы считаются следующие события: война, военные действия, восстание, мобилизация, забастовки, эпидемии, пожары, взрывы, дорожные и природные катастрофы, акты органов власти, влияющие на исполнение обязательств Сторон, отключение и сбои в цифровых сетях, а также другие события, которые компетентный орган признает обстоятельствами непреодолимой силы.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>9.4. О факте наступления обстоятельств непреодолимой силы каждая Сторона обязуется уведомить другую Сторону и подтвердить такие обстоятельства и их продолжительность документом, выданным компетентным органом. Такое документальное подтверждение не требуется, если данные обстоятельства фактически являются общеизвестными и признаются обеими Сторонами.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-bottom:10.0pt;text-align:center;line-height:115%;'><strong><span style='font-size:13px;line-height:115%;font-family:"Arial",sans-serif;'>9.&nbsp;</span></strong><strong><span style='font-size:13px;line-height:115%;font-family:"Arial",sans-serif;'>Срок действия Оферты и иные условия</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.1. Настоящая Оферта вступает в силу с момента его Акцепта и осуществление оплаты Заказчиком через Платежные системы и действует до выполнения сторонами своих обязательств по настоящей Оферты.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-bottom:10.0pt;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>8.2. Отношения между сторонами прекращаются при выполнении ими всех условий настоящей оферты и полного завершения расчетов.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-bottom:10.0pt;text-align:center;'><strong><span style='font-size:15px;font-family:"Arial",sans-serif;'>ПРИЛОЖЕНИЕ №1<br>&nbsp;К ПУБЛИЧНОЙ ОФЕРТЕ НА ОКАЗАНИЕ УСЛУГ В СФЕРЕ ИЗГОТОВЛЕНИЕ КОНТЕНТА</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>1. Термины</span></strong><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>&nbsp;и определения, используемые в Приложении<br>&nbsp;</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&laquo;Произведения&raquo;</span></strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&nbsp;&ndash; аудио- и/или аудиовизуальные произведения, которые были предоставлены Заказчиком Исполнителю для получения Услуги на момент акцепта, права на которые передаются, в виде музыкальных произведений, песен, исполнений, фонограмм, видеограмм и других объектов авторского права и смежных прав.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:11px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&laquo;Музыкальные произведения&raquo;</span></strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&nbsp;&ndash; любые исключительно звуковые записи какого-либо исполнения, иных звуков, за исключением звуковой записи, включенной в аудиовизуальное произведение, которые были предоставлены Заказчиком Исполнителю для получения Услуги на момент акцепта.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:11px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&laquo;Аудиовизуальные произведения&raquo;</span></strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&nbsp;&ndash; произведения, состоящие из зафиксированной серии связанных между собой изображений (с сопровождением или без сопровождения их звуком), предназначенные для зрительного и слухового (в случае сопровождения звуком) восприятия с помощью соответствующих технических средств, в том числе произведения кинематографии и все произведения, выраженные средствами, аналогичными кинематографическим (теле- и видеофильмы, диафильмы, видеоклипы, слайд-фильмы и иные произведения), независимо от способа их первоначальной или последующей фиксации, которые были предоставлены Заказчиком Исполнителю для получения Услуги на момент акцепта.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:11px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:11px;font-family:"Arial",sans-serif;color:black;'>&laquo;</span></strong><strong><span style='font-size:11px;font-family:"Arial",sans-serif;color:black;'>DSP</span></strong><strong><span style='font-size:11px;font-family:"Arial",sans-serif;color:black;'>&raquo;</span></strong><span style='font-size:11px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span><span style='font-size:11px;font-family:"Arial",sans-serif;'>&ndash; <span style="color:black;">(Digital Service Provider) &ndash; технический сервис (электронная площадка/ платформа для загрузки/прослушивания/ использования музыки или видео (видеоклипов) вроде iTunes, Apple Music, Spotify, Google Play Music, YouTube, YouTube Music и т. п.), который имеет адрес или определитель местонахождения в цифровых сетях (включая on-line и off-line режимы), доступный потребителям с помощью любых технических средств.</span></span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:11px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&laquo;Трейлер&raquo;&nbsp;</span></strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&ndash; результат выполненной работы Исполнителя, небольшой рекламный ролик фильма, выставленный для промоушна в интернет.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:11px;font-family:"Arial",sans-serif;'><br> <strong>&laquo;Тизер&raquo;&nbsp;</strong>&ndash; результат выполненной работы Исполнителя, рекламное фото и/или видео-сообщение, построенное как загадка, которое содержит часть Произведении и информации о ней. Тизеры обычно появляются на раннем этапе продвижение произведений и служат для создания интриги вокруг нее.&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:11px;font-family:"Arial",sans-serif;'><br> <strong>&laquo;Обложка&raquo;&nbsp;</strong>&ndash;<span style="color:black;background:white;">&nbsp;</span>результат выполненной работы Исполнителя<span style="color:black;background:white;">, изображение, которое загружаемое в&nbsp;</span></span><span style='font-size:11px;font-family:"Arial",sans-serif;color:black;background:white;'>DSP</span><span style='font-size:11px;font-family:"Arial",sans-serif;color:black;background:white;'>&nbsp;вместе с произведением и которое пользователи видят рядом с названием Произведений.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:11px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&laquo;Художественный совет</span></strong><strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&raquo;&nbsp;</span></strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&ndash;<strong>&nbsp;</strong>экспертно-консультативный орган Исполнителя, занимающийся оценкой Произведений.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:11px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&laquo;Оценка&raquo;&nbsp;</span></strong><span style='font-size:11px;font-family:"Arial",sans-serif;'>&ndash;&nbsp;</span><span style='font-size:11px;font-family:"Arial",sans-serif;'>действия Художественного совета по анализу и определению оценки Произведений.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>2. Категории, порядок определении оценок, предоставляемые работы и стоимость Услуг</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:14px;font-family:"Arial",sans-serif;'><br>&nbsp;</span><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>2.1.&nbsp;</span></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>Музыкальные произведения</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <table style="margin-left:-.25pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td style="width:60.2pt;border:solid windowtext 1.0pt;background:#DFDFDF;padding:0cm 5.4pt 0cm 5.4pt;height:20.8pt;">
                                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>Категория</span></strong></p>
                            </td>
                            <td style="width:187.3pt;border:solid windowtext 1.0pt;border-left:  none;background:#DFDFDF;padding:0cm 5.4pt 0cm 5.4pt;height:20.8pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Описание / Оценка</span></strong></p>
                            </td>
                            <td style="width:168.9pt;border:solid windowtext 1.0pt;border-left:  none;background:#DFDFDF;padding:0cm 5.4pt 0cm 5.4pt;height:20.8pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Предоставляемая работа</span></strong></p>
                            </td>
                            <td style="width:89.8pt;border:solid windowtext 1.0pt;border-left:  none;background:#DFDFDF;padding:0cm 5.4pt 0cm 5.4pt;height:20.8pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Стоимость</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:60.2pt;border:solid windowtext 1.0pt;border-top:  none;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:31.4pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;'>1</span></p>
                            </td>
                            <td style="width:187.3pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:31.4pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Содержание произведений и качество звукозаписи на высшем уровне</span></p>
                            </td>
                            <td style="width:168.9pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:31.4pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Изготовление обложек для&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>DSP</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>и для тизера, создание (видео) тизера</span></p>
                            </td>
                            <td style="width:89.8pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:31.4pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>*</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Бесплатно</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:60.2pt;border:solid windowtext 1.0pt;border-top:  none;background:#D6DEEF;padding:0cm 5.4pt 0cm 5.4pt;height:32.15pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;'>2</span></p>
                            </td>
                            <td style="width:187.3pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#D6DEEF;padding:0cm 5.4pt 0cm 5.4pt;height:32.15pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Содержание произведений и качество звукозаписи на хорошем уровне</span></p>
                            </td>
                            <td style="width:168.9pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#D6DEEF;padding:0cm 5.4pt 0cm 5.4pt;height:32.15pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Изготовление обложек для&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>DSP</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>и для тизера, создание (видео) тизера</span></p>
                            </td>
                            <td style="width:89.8pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#D6DEEF;padding:0cm 5.4pt 0cm 5.4pt;height:32.15pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>200&nbsp;000 (двести тысяч) сум</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:60.2pt;border:solid windowtext 1.0pt;border-top:  none;background:#FBEEC9;padding:0cm 5.4pt 0cm 5.4pt;height:31.4pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;'>3</span></p>
                            </td>
                            <td style="width:187.3pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#FBEEC9;padding:0cm 5.4pt 0cm 5.4pt;height:31.4pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Содержание произведений и качество звукозаписи на удовлетворительном уровне</span></p>
                            </td>
                            <td style="width:168.9pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#FBEEC9;padding:0cm 5.4pt 0cm 5.4pt;height:31.4pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Изготовление обложек для&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>DSP</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>и для тизера, создание (видео) тизера</span></p>
                            </td>
                            <td style="width:89.8pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#FBEEC9;padding:0cm 5.4pt 0cm 5.4pt;height:31.4pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>1&nbsp;000&nbsp;000 (один миллион) сум</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:60.2pt;border:solid windowtext 1.0pt;border-top:  none;background:#F7E0D2;padding:0cm 5.4pt 0cm 5.4pt;height:20.9pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:13px;font-family:  "Arial",sans-serif;color:black;'>4</span></p>
                            </td>
                            <td style="width:187.3pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#F7E0D2;padding:0cm 5.4pt 0cm 5.4pt;height:20.9pt;">
                                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Содержание произведений и качество звукозаписи на низком уровне</span></p>
                            </td>
                            <td colspan="2" style="width:258.7pt;border-top:none;border-left:  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#F7E0D2;padding:0cm 5.4pt 0cm 5.4pt;height:20.9pt;">
                                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Музыкальным произведениям, получившим оценку данной категории, услуги не предоставляются</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Arial",sans-serif;'>2.1.&nbsp;</span></strong><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>Аудиовизуальные произведения</span></strong></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <table style="margin-left:-.25pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td style="width:63.8pt;border:solid windowtext 1.0pt;background:#DFDFDF;padding:0cm 5.4pt 0cm 5.4pt;height:19.6pt;">
                                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;'>Категория</span></strong></p>
                            </td>
                            <td style="width:183.7pt;border:solid windowtext 1.0pt;border-left:  none;background:#DFDFDF;padding:0cm 5.4pt 0cm 5.4pt;height:19.6pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Описание / Оценка&nbsp;</span></strong></p>
                            </td>
                            <td style="width:170.7pt;border:solid windowtext 1.0pt;border-left:  none;background:#DFDFDF;padding:0cm 5.4pt 0cm 5.4pt;height:19.6pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Предоставляемая работа</span></strong></p>
                            </td>
                            <td style="width:87.9pt;border:solid windowtext 1.0pt;border-left:  none;background:#DFDFDF;padding:0cm 5.4pt 0cm 5.4pt;height:19.6pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Стоимость</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:63.8pt;border:solid windowtext 1.0pt;border-top:  none;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:22.7pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;'>1</span></p>
                            </td>
                            <td rowspan="2" style="width:183.7pt;border-top:none;border-left:  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:22.7pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Содержание и качество аудиовизуальной произведений на высшем уровне</span></p>
                            </td>
                            <td style="width:170.7pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:22.7pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Изготовление обложек для&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>DSP</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>&nbsp;&nbsp;</span></p>
                            </td>
                            <td style="width:87.9pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:22.7pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>*</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Бесплатно</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:63.8pt;border:solid windowtext 1.0pt;border-top:  none;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:29.65pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;'>2</span></p>
                            </td>
                            <td style="width:170.7pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:29.65pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Изготовление трейлера, изготовление обложек для&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>DSP</span></p>
                            </td>
                            <td style="width:87.9pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#DEEBD6;padding:0cm 5.4pt 0cm 5.4pt;height:29.65pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>200&nbsp;000 (двести тысяч) сум</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:63.8pt;border:solid windowtext 1.0pt;border-top:  none;background:#FBEEC9;padding:0cm 5.4pt 0cm 5.4pt;height:26.05pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;'>3</span></p>
                            </td>
                            <td style="width:183.7pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#FBEEC9;padding:0cm 5.4pt 0cm 5.4pt;height:26.05pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Содержание и качество аудиовизуальной произведений на удовлетворительном уровне</span></p>
                            </td>
                            <td style="width:170.7pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#FBEEC9;padding:0cm 5.4pt 0cm 5.4pt;height:26.05pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Изготовление трейлера, изготовление обложек для&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>DSP</span></p>
                            </td>
                            <td style="width:87.9pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#FBEEC9;padding:0cm 5.4pt 0cm 5.4pt;height:26.05pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>500&nbsp;000 (пятьсот тысяч) сум</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:63.8pt;border:solid windowtext 1.0pt;border-top:  none;background:#F3DDCE;padding:0cm 5.4pt 0cm 5.4pt;height:25.6pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;'>4</span></p>
                            </td>
                            <td style="width:183.7pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#F3DDCE;padding:0cm 5.4pt 0cm 5.4pt;height:25.6pt;">
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Содержание и качество аудиовизуальной произведений на низком уровне</span></p>
                            </td>
                            <td colspan="2" style="width:258.6pt;border-top:none;border-left:  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#F3DDCE;padding:0cm 5.4pt 0cm 5.4pt;height:25.6pt;">
                                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>Аудиовизуальным произведениям, получившим оценку данной категории, услуги не предоставляются</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:14px;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><em><span style='font-size:11px;font-family:"Arial",sans-serif;'>*при оказании Услуги по данной категории Исполнитель вправе на свое усмотрение устанавливать Предоставляемых работ и освобождается от обязанностей, указанных в пунктах 3.2 и 3.5 Оферты на оказание услуг в сфере изготовление контента.</span></em></p>
                `
            }}>

            </div>
        </div>
    );
}