import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { post } from '../utils/request';
import { arrayToFormData } from '../utils/functions';

export default function PasswordForm({ closeModal }) {
    const { t, i18n } = useTranslation();

    const defaultValues = {
        password: "",
        new_password: "",
        repeat_new_password: ""
    };

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm({ defaultValues });

    const password = useRef({});
    password.current = watch("new_password", "");

    const [showPassword, setShowPassword] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async data => {
        try {
            setIsLoading(true);

            let res = await post('account/change-password', arrayToFormData(data));

            if (res.status === 'error') {
                if (res.code == 1) {
                    toast.error(t("Пожалуйста, авторизуйтесь"), { toastId: "password-msg", updateId: "password-msg" });
                } else if (res.code == 2) {
                    toast.error(t("Ваш аккаунт не найден"), { toastId: "password-msg", updateId: "password-msg" });
                } else if (res.code == 3) {
                    toast.error(t("Ошибка сервера, повторите попытку"), { toastId: "password-msg", updateId: "password-msg" });
                } else if (res.code == 4) {
                    toast.error(t("Новые пароли не совпадают"), { toastId: "password-msg", updateId: "password-msg" });
                } else if (res.code == 5) {
                    toast.error(t("Текущий пароль не правильно"), { toastId: "password-msg", updateId: "password-msg" });
                }
            } else if (res.status == 'success') {
                toast.success(t("Новый пароль успешно сохранён"), { toastId: "password-msg", updateId: "password-msg" });

                reset(defaultValues);

                if (closeModal)
                    closeModal();
            }

            setIsLoading(false);
        } catch (ex) {

        }
    };

    return (
        <div className="rizanova-login transition-all transform">
            {/* {closeModal && <a role="button" className="close" onClick={closeModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path fill="#222" fillRule="evenodd" d="M12 10.75L3.25 2 2 3.25 10.75 12 2 20.75 3.25 22 12 13.25 20.75 22 22 20.75 13.25 12 22 3.25 20.75 2z" />
                </svg>
            </a>} */}

            <div className="d-flex justify-content-center w-100">
                <h3>{t("Смена пароля")}</h3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group position-relative">
                    <input type={showPassword ? 'text' : "password"} {...register("password", {
                        required: true
                    })} className={errors.password ? 'has-error' : ''} placeholder={t("Текущий пароль")} />
                    <a role="button" className={"eye " + (showPassword ? 'active' : '')} onClick={() => setShowPassword(!showPassword)}>
                        {/* Eye svg */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                            <path d="M10.0007 0.75C5.83398 0.75 2.27565 3.34167 0.833984 7C2.27565 10.6583 5.83398 13.25 10.0007 13.25C14.1673 13.25 17.7256 10.6583 19.1673 7C17.7256 3.34167 14.1673 0.75 10.0007 0.75ZM10.0007 11.1667C7.70065 11.1667 5.83398 9.3 5.83398 7C5.83398 4.7 7.70065 2.83333 10.0007 2.83333C12.3006 2.83333 14.1673 4.7 14.1673 7C14.1673 9.3 12.3006 11.1667 10.0007 11.1667ZM10.0007 4.5C8.61732 4.5 7.50065 5.61667 7.50065 7C7.50065 8.38333 8.61732 9.5 10.0007 9.5C11.384 9.5 12.5007 8.38333 12.5007 7C12.5007 5.61667 11.384 4.5 10.0007 4.5Z" />
                        </svg>
                    </a>
                </div>
                <div className="form-group mb-0 mt-3">
                    <div>
                        <input type={showPassword ? 'text' : "password"}
                            {...register("new_password", {
                                required: true,
                                minLength: {
                                    value: 7,
                                    message: t("Пароль должен состоять не менее чем из 7 символов")
                                }
                            })} className={errors.new_password ? 'has-error' : ''} placeholder={t("Новый пароль")}
                        />
                    </div>
                    {errors.new_password && errors.new_password.message.length > 0 && <p className="error-block">{errors.new_password.message}</p>}

                </div>
                <div className="form-group mb-0 mt-3">
                    <input type={showPassword ? 'text' : "password"}
                        {...register("repeat_new_password", {
                            required: true,
                            validate: value => value === password.current || t("Пароли не совпадают")
                        })}
                        className={errors.repeat_new_password ? 'has-error' : ''} placeholder={t("Повторите новый пароль")}
                    />
                </div>
                {errors.repeat_new_password && errors.repeat_new_password.message.length > 0 && <p className="error-block">{errors.repeat_new_password.message}</p>}

                <div>
                    <button type="submit" className="submit" disabled={isLoading}>{
                        isLoading ? <i className="fa fa-spinner rotating"></i> : t("Подтвердить")
                    }</button>
                </div>
            </form >
        </div >
    )
}