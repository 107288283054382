import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from '../components/context';
import InputMask from '../components/InputMask';
import { arrayToFormData, useIsMounted } from '../utils/functions';
import { post } from '../utils/request';

export default function Signup() {
    const { t } = useTranslation();

    const authContext = useContext(AuthContext);
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [step, setStep] = useState(1);
    const [user, setUser] = useState(null);
    const [timeLeft, setTimeLeft] = useState(120);

    const navigate = useNavigate();

    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const { register: codeRegister, handleSubmit: codeHandleSubmit, formState: { errors: codeErrors }, reset: codeReset } = useForm({ defaultValues: { code: '' } });

    const submitHandler = async (data) => {
        setIsLoading(true);

        try {
            let res = await post('user/signup', {
                username: data.username,
                password: data.password,
                full_name: data.full_name,
                phone: data.phone,
            });

            if (res?.status === 'error') {
                if (isMounted) {
                    if (res.code == 4) {
                        toast.error('Этот номер телефона уже занят');
                    } else if (res.code == 1) {
                        toast.error("Номер телефона, пароль или Ф.И.О. не введены");
                    }
                }
            } else {
                if (res.user.status == 10) {
                    toast.success(t("Вы успешно зарегистрировались"), { toastId: "register-toast", updateId: "register-toast" });

                    if (isMounted) {
                        authContext.login({ ...res.user });
                        navigate("/");
                    }
                } else {
                    toast.info(t("Пожалуйста, подтвердите номер телефона"), { toastId: "register-toast", updateId: "register-toast" });
                    setTimeLeft(120);
                    setStep(2);
                    setUser(res.user);
                }

            }
        } catch (ex) {

        }

        setIsLoading(false);
    };


    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            if (step == 2)
                setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft, step]);

    const onConfirm = async (data) => {
        if (!user) {
            setStep(1);
            return;
        }

        try {
            setIsLoading(true);

            let res = await post('user/confirm', {
                code: data.code,
                id: user.id
            });

            if (res.status === 'error') {
                toast.error(t("Вы ввели неправильный код подтверждения, повторите попытку"))
            } else if (res.status == 'success') {
                toast.success(t("Вы успешно подтвердили ваш номер"), { toastId: "register-toast", updateId: "register-toast" });

                if (isMounted) {
                    authContext.login({ ...res.user });
                    navigate("/");
                }
            }

            setIsLoading(false);
        } catch (ex) {
            setIsLoading(false);
        }
    }

    return <div className="container py-5">
        <Helmet>
            <title>{"Регистрация"}</title>
        </Helmet>

        <div className="d-flex justify-content-center">
            <div className="rizanova-login transition-all transform">
                <div className="login">
                    {step == 1 ? (
                        <>
                            <div className="d-flex w-100">
                                <a role="button" className="login-text-btn mb-0" onClick={() => navigate("/")}> ← {t("Назад")}</a>
                            </div>
                            <div className="d-flex justify-content-center w-100">
                                <h3>{("Регистрация")}</h3>
                            </div>
                            <form onSubmit={handleSubmit(submitHandler)}>
                                <div className="form-group">
                                    <div>
                                        <input name="full_name" autoComplete="off" placeholder="Ф.И.О." type="text" {...register("full_name", { required: true, maxLength: 100 })} />
                                        {errors.full_name && errors.full_name.message.length > 0 && <p className="error-block">{errors.full_name.message}</p>}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div>
                                        <InputMask
                                            mask="\+\9\9\8999999999"
                                            className={errors.username ? 'has-error' : ''}
                                            placeholder={t("Введите номер телефона")}
                                            control={control}
                                            name="username"
                                            rules={{
                                                required: true,
                                                pattern: /^\+[0-9]{12}$/
                                            }}
                                        />
                                        {errors.username && errors.username.message.length > 0 && <p className="error-block">{errors.username.message}</p>}
                                    </div>
                                </div>

                                <div className="form-group mb-1 position-relative">
                                    <input type={showPassword ? 'text' : "password"} {...register("password", { required: true })} className={errors.password ? 'has-error' : ''} placeholder={("Введите пароль")} />

                                    <a role="button" className={"eye " + (showPassword ? 'active' : '')} onClick={() => setShowPassword(!showPassword)}>
                                        {/* Eye svg */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                                            <path d="M10.0007 0.75C5.83398 0.75 2.27565 3.34167 0.833984 7C2.27565 10.6583 5.83398 13.25 10.0007 13.25C14.1673 13.25 17.7256 10.6583 19.1673 7C17.7256 3.34167 14.1673 0.75 10.0007 0.75ZM10.0007 11.1667C7.70065 11.1667 5.83398 9.3 5.83398 7C5.83398 4.7 7.70065 2.83333 10.0007 2.83333C12.3006 2.83333 14.1673 4.7 14.1673 7C14.1673 9.3 12.3006 11.1667 10.0007 11.1667ZM10.0007 4.5C8.61732 4.5 7.50065 5.61667 7.50065 7C7.50065 8.38333 8.61732 9.5 10.0007 9.5C11.384 9.5 12.5007 8.38333 12.5007 7C12.5007 5.61667 11.384 4.5 10.0007 4.5Z" />
                                        </svg>
                                    </a>
                                </div>

                                <div>
                                    <button type="submit" className="submit" disabled={isLoading}>{
                                        isLoading ? <i className="fa fa-spinner rotating"></i> : ("Зарегистрироваться")
                                    }</button>
                                </div>
                            </form>
                        </>
                    ) : (
                        <>
                            <div className="d-flex justify-content-center w-100">
                                <h3>{("Подтверждение номер телефона")}</h3>
                            </div>
                            <div className="d-flex justify-content-center w-100">
                                <a role="button" className="login-text-btn" onClick={() => setStep(1)}> ← {t("Назад")}</a>
                            </div>
                            <form onSubmit={codeHandleSubmit(onConfirm)}>
                                <div className="form-group">
                                    <div>
                                        <input type='text' autoComplete='off' {...codeRegister("code", { required: true })} className={codeErrors.code ? 'has-error' : ''} placeholder={t("Введите полученный код")} />
                                    </div>
                                </div>

                                <div className='mb-1'>
                                    {timeLeft > 0 ? (
                                        <a role="button" className="remember-me">{t("Отправить код повторно можно через")} {moment.utc(timeLeft * 1000).format("mm:ss")}</a>
                                    ) : (
                                        <a role="button" className="remember-me" onClick={async () => {
                                            let res = await post(`user/send-again`, arrayToFormData({ id: user.id }));

                                            if (res.status == "success") {
                                                setTimeLeft(120);
                                                toast.info(t("Повторный код отправлен на номер"));
                                            }

                                        }}>{t("Отправить код повторно")}</a>
                                    )}
                                </div>

                                <div>
                                    <button type="submit" className="submit" disabled={isLoading}>{
                                        isLoading ? <i className="fa fa-spinner rotating"></i> : ("Подтвердить")
                                    }</button>
                                </div>
                            </form>
                        </>
                    )}

                </div>
            </div>
        </div>
    </div>
}