import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../components/context';
import { useTranslation } from 'react-i18next';
import PasswordModal from '../components/PasswordModal';
import SideBar from '../components/SideBar';
import { Helmet } from 'react-helmet';
import Header from './Header';

export default function Home() {
    const authContext = useContext(AuthContext);
    const { t } = useTranslation();
    const [isPasswordModalOpen, setIsPasswodModalOpen] = useState(false);

    return (
        <div className={`rizanova-account`}>
            <Header/>
            <Helmet>
                <title>{"RizaNova Payment"}</title>
            </Helmet>

            <div className="container min-view">
                <h1 className="big-header font-weight-400">{t("Профиль")}</h1>

                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <SideBar />
                    </div>

                    <div className="col-lg-9 col-md-8 d-flexjustify-content-center">
                        <div className="profile w-100">
                            <div className="row justify-content-center">
                                <div className="col-xl-10 col-lg-10">
                                    <div className="row">
                                        <div className="col-md-3 d-flex flex-wrap align-items-center">
                                            <div className="first-letter w-100">
                                                {authContext.authState.user?.full_name[0]?.toUpperCase()}
                                            </div>
                                        </div>
                                        <div className="col-md-9 d-flex align-items-center">
                                            <div className="detail">
                                                <div className="d-flex">
                                                    <div className="label">{t("Имя")}:</div>
                                                    <div className="value">{authContext.authState.user?.full_name}</div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="label">{t("Логин")}:</div>
                                                    <div className="value">{authContext.authState.user?.username}</div>
                                                </div>
                                                {authContext.authState.user?.email && authContext.authState.user?.username != authContext.authState.user?.email && <div className="d-flex">
                                                    <div className="label">{t("Почта")}:</div>
                                                    <div className="value">{authContext.authState.user?.email}</div>
                                                </div>}
                                                <div className="d-flex">
                                                    <div className="label">{t("Дата регистрации")}:</div>
                                                    <div className="value">{authContext.authState.user?.created_at}</div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="label">Партнер ID:</div>
                                                    <div className="value">{authContext.authState.user?.id}</div>
                                                </div>

                                                <div className="d-flex">
                                                    <div className="label">
                                                        <a role="button" className='theme-thing' onClick={() => {
                                                            setIsPasswodModalOpen(true);
                                                        }}>{t("Сменить пароль")}</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PasswordModal isOpen={isPasswordModalOpen} setIsOpen={setIsPasswodModalOpen} />
            </div>
        </div>
    );
}